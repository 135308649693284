import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import BuildIdentifier from './BuildIdentifier';
import Logo from './logo512text.png';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100vw',
    position: 'absolute',
    height: '0',
  },
  logo: {
    marginTop: '45px',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 1,
    height: '10rem',
    width: '10rem',
  },
  build: {
    position: 'absolute',
    height: '1rem',
    bottom: '1.5rem',
    width: '100%',
    textAlign: 'center',
  },
}));

function CognitoFrame() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.logo} src={Logo} alt="Logo" />

      <div className={classes.build}>
        <BuildIdentifier />
      </div>
    </div>
  );
}

export default CognitoFrame;
