import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Auth } from 'aws-amplify';

import { dateToRFC3339String } from '../../helpers/time';
import { useAppDispatch, useAppSelector } from '../Data/reduxHooks';
import {
  getCurrentLoggedUser,
  getEulaConfirmation,
  setEulaConfirmed,
  setEulaConfirmedDate,
} from '../Data/userReduxSlice';

const drawerWidth = '240px';
const bannerHeight = '64px';
const useStyles = makeStyles((theme) => ({
  root: {},
  hoverButton: {
    color: 'lightgrey',
  },
  float: {
    position: 'absolute',
    padding: theme.spacing(1),
    left: 0,
    top: 0,
  },
  flex2: {
    display: 'inline-flex',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
      minHeight: 530,
      height: `calc(100vh - ${bannerHeight})`,
    },
  },
  nav: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      height: bannerHeight,
    },
    // position: '-webkit-sticky',
    position: 'sticky',
    // left: 0,
    top: 0,
    zIndex: 1,
  },
  title: {
    color: 'rgb(54,96,145)',
  },
  labelTitle: {
    color: 'rgb(79,129,189)',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  paddingLeft: { paddingLeft: theme.spacing(5) },
}));

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  pt: 2,
  px: 4,
  pb: 3,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 80px)',
  width: 800,
  borderRadius: 2,
};

function InfoToolTip() {
  const classes = useStyles();
  const [isAccept, toggleAcceptTerms] = React.useState<boolean>(false);
  const currentUser = useAppSelector(getCurrentLoggedUser);
  const eulaConfirmed = useAppSelector(getEulaConfirmation);
  const dispatch = useAppDispatch();

  const signOut = (e: any) => {
    e.preventDefault();

    Auth.updateUserAttributes(currentUser, {
      'custom:EULAConfirmed': '0',
    }).then(() => {
      Auth.signOut().then(() => {
        window.location.reload();
      });
    });
  };

  const submitEula = () => {
    const newDate = new Date();
    const dateString = dateToRFC3339String(newDate);
    const isEULAAccepted = isAccept ? '1' : '';

    Auth.updateUserAttributes(currentUser, {
      'custom:EULAConfirmed': isEULAAccepted,
      'custom:EULAConfirmedDate': dateString,
    }).then(() => {
      dispatch(setEulaConfirmed(true));
      dispatch(setEulaConfirmedDate(dateString));
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <Modal
          open={!eulaConfirmed}
          onClose={(e: any) => e.preventDefault()}
          disableEscapeKeyDown
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style }}>
            <h2 className={classes.title}>OmniEye End-User License Agreement</h2>
            <p>Last updated: July 8th 2022</p>

            <p id="">
              These Terms and Conditions (&quot;Terms&quot;, &quot;Terms and Conditions&quot;) govern your relationship
              with OmniEye operated by OmniEye New Zealand Limited.
            </p>

            <p id="">
              Please read these Terms and Conditions carefully before using our OmniEye dashboard website (the
              &quot;Software&quot;).
            </p>

            <p id="">
              Your access to and use of the Software is conditional on your acceptance of and compliance with these
              Terms. These Terms apply to all visitors, users and others who access or use the Software.
            </p>

            <p id="">
              By accessing or using the Software you agree to be bound by these Terms. If you disagree with any part of
              the terms, then you may not access the Software.
            </p>

            <h3 className={classes.labelTitle}>1. Acknowledgment</h3>
            <hr />

            <p id="">
              By clicking the &quot;I Agree&quot; button, downloading or using the Application, You are agreeing to be
              bound by the terms and conditions of this Agreement. If You do not agree to the terms of this Agreement,
              do not click on the &quot;I Agree&quot; button and do not use the Application.
            </p>

            <h3 className={classes.labelTitle}>2. Software terms of use</h3>
            <hr />
            <p id="">2.1 When accessing and using the Software, the User shall:</p>

            <p className={classes.paddingLeft}>
              (a) ensure that their username(s) and password(s) required to access the Software is kept secure and
              confidential. The User shall ensure that strong passwords are selected. The User shall immediately notify
              OmniEye of any unauthorised use of its password(s) or the Software, or any other breach of security.
            </p>

            <p className={classes.paddingLeft}>
              (b) not attempt to undermine the security or integrity of OmniEye&apos;s computing systems or networks or,
              where the system is hosted by a third party, that third party&apos;s computing systems and networks;
            </p>

            <p className={classes.paddingLeft}>(c) not use, or misuse, the Software in any way;</p>

            <p className={classes.paddingLeft}>
              (d) not attempt to gain unauthorised access to any materials other than those to which the User has been
              given express permission to access;
            </p>

            <p className={classes.paddingLeft}>
              (e) not transmit or input any files that may damage any other person&apos;s computing devices or software,
              content that may be offensive, or material in violation of any law or third party&apos;s rights;
            </p>

            <p className={classes.paddingLeft}>
              (f) not attempt to modify, copy, adapt, reproduce, disassemble, decompile or reverse engineer any computer
              programs used to deliver the Software;
            </p>

            <p className={classes.paddingLeft}>
              (g) not download or make copies of the results or videos produced by the Software other than via the
              documented system features or integrations unless OmniEye has agreed prior in writing to such download or
              copy; and
            </p>

            <p id="">
              2.2 The User shall comply with any other terms of use of the Software set by OmniEye from time to time.
            </p>

            <FormControlLabel
              control={<Checkbox />}
              checked={isAccept}
              label="Click to agree to the terms and agreement"
              onChange={() => {
                toggleAcceptTerms((accepted) => !accepted);
              }}
            />

            <div>
              <Button fullWidth className={classes.button} variant="contained" onClick={signOut}>
                Cancel
              </Button>

              <Button
                fullWidth
                className={classes.button}
                variant="contained"
                disabled={!isAccept}
                onClick={submitEula}
              >
                I Agree
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default InfoToolTip;
