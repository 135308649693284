import { Auth, Storage } from 'aws-amplify';
import AWS from 'aws-sdk';
import { CommonPrefixList } from 'aws-sdk/clients/s3';

import aws_exports from '../../awsConfig';
import { APIConfirmedIdentity } from './dataInterfaces';

export const getAllS3Directories = async (callback: (farmNames: string[]) => void) => {
  const creds = await Auth.currentCredentials();

  AWS.config.credentials = creds;
  AWS.config.update({ region: aws_exports.aws_cognito_region });
  const params = {
    Bucket: aws_exports.aws_user_files_s3_bucket ?? '',
    Prefix: '',
    Delimiter: '/',
  } as AWS.S3.ListObjectsV2Request;

  const s3 = new AWS.S3();

  const getRootDirectories = async (
    // eslint-disable-next-line @typescript-eslint/no-shadow
    params: AWS.S3.ListObjectsV2Request,
    out: string[] = []
  ) => {
    let IsTruncated;
    let CommonPrefixes: CommonPrefixList | undefined;
    let NextContinuationToken: string | undefined;

    do {
      // eslint-disable-next-line no-await-in-loop
      ({ IsTruncated, NextContinuationToken, CommonPrefixes } = await s3
        .listObjectsV2({
          ...params,
          ContinuationToken: NextContinuationToken,
        })
        .promise());
      CommonPrefixes?.forEach(({ Prefix }) => {
        if (Prefix !== undefined) out.push(Prefix?.substring(0, Prefix.length - 1));
      });
    } while (IsTruncated);

    return out;
  };

  callback(await getRootDirectories(params));
};

export const checkIdentityAccuracy = (eid: string | undefined, state: APIConfirmedIdentity | undefined) => {
  if (state !== undefined && eid !== undefined) {
    if (state.eid !== undefined) return state.eid === eid;
    if (state.wrong_eid.length > 0 && state.wrong_eid.includes(eid)) return false;
  }

  return null;
};

/* retrieve data from S3 Bucket */
export const getS3file = async (fn: string) => {
  try {
    const url = await Storage.get(fn, { download: true });

    return await new Response(url.Body as Blob).json();
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.error(e.message);
    }

    return [];
  }
};
