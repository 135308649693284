// Tell webpack this JS file uses this image
import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import Logo from './logo512text.png';

const useStyles = makeStyles(() => ({
  logo: {
    /* SideBare width is 240 */
    width: 240 / 2,
    marginLeft: 240 / 4,
    marginTop: 240 / 8,
    marginBottom: 240 / 8,
  },
}));

function SideBarIrisLogo() {
  const classes = useStyles();

  return <img className={classes.logo} src={Logo} alt="Logo" />;
}

export default SideBarIrisLogo;
